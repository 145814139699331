import React from "react";
import LondonBanner from "./LondonBanner";
import "../assets/css/LondonHome.css";
import "../assets/css/karsonNewLayout.css";
import EngWedRing from "./EngWedRing";
import BestSeller from "./BestSeller";
import WhyBuy from "./WhyBuy";
import VideoBanner from "./VideoBanner";
import LearnDiamond from "./LearnDiamond";
import WhatPeopleSay from "./WhatPeopleSay";
import InstaLondon from "./InstaLondon";
import NewsLetter from "./NewsLetter";
import LondonFooter from "../footer/LondonFooter";
import WeddingBands from "./WeddingBands";
import ShopByShap from "./ShopByShap";
import AnniversaryAndEternity from "./AnniversaryAndEternity";
import ShopByCategory from "./ShopByCategory";
import { useEffect } from "react";

function LondonHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
})
  return (
    <>
      <LondonBanner />
      <BestSeller />
      <WeddingBands/>
      <ShopByShap/>
      <AnniversaryAndEternity/>
      <VideoBanner/>
      <ShopByCategory/>
      {/* <EngWedRing /> */}
      {/* <WhyBuy/> */}
      {/* <LearnDiamond/> */}
      {/* <WhatPeopleSay/> */}
      {/* <InstaLondon /> */}

      <NewsLetter />
    </>
  );
}

export default LondonHome;
