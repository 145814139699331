import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import round from '../../../../Assets/images/karson-new-layout-img/shapes-img/round.webp'
import oval from '../../../../Assets/images/karson-new-layout-img/shapes-img/oval.webp'
import princess from '../../../../Assets/images/karson-new-layout-img/shapes-img/princess.webp'
import emerald from '../../../../Assets/images/karson-new-layout-img/shapes-img/emerald.webp'
import pear from '../../../../Assets/images/karson-new-layout-img/shapes-img/pear.webp'
import marquise from '../../../../Assets/images/karson-new-layout-img/shapes-img/marquise.webp'
import cushion from '../../../../Assets/images/karson-new-layout-img/shapes-img/cushion.webp'
import radiant from '../../../../Assets/images/karson-new-layout-img/shapes-img/radiant.webp'
import asscher from '../../../../Assets/images/karson-new-layout-img/shapes-img/asscher.webp'
import heart from '../../../../Assets/images/karson-new-layout-img/shapes-img/heart.webp'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Slider from "react-slick";

const ShopByShap = () => {
    const history = useHistory();
    const settingsShap = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay:true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay:true,
          initialSlide: 2,
          dots: true, 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay:true,
          dots: true, 
        }
      }
    ]
      };
    const ShapData = [
        {
            name: "Round",
            img: round,
            url: "/diamonds/shape=Round",
        },
        {
            name: "Princess",
            img: princess,
            url: "/diamonds/shape=Princess",
        },
        {
            name: "Cushion",
            img: cushion,
            url: "/diamonds/shape=Cushion",
        },
        {
            name: "Emerald",
            img: emerald,
            url: "/diamonds/shape=Emerald",
        },
        {
            name: "Oval",
            img: oval,
            url: "/diamonds/shape=Oval",
        },
        {
            name: "Radiant",
            img: radiant,
            url: "/diamonds/shape=Radiant",
        },
        {
            name: "Asscher",
            img: asscher,
            url: "/diamonds/shape=Asscher",
        },
        
        {
            name: "Marquise",
            img: marquise,
            url: "/diamonds/shape=Marquise",
        },
      
        {
            name: "Pear",
            img: pear,
            url: "/diamonds/shape=Pear",
        }, 
        
        {
            name: "heart",
            img: heart,
            url: "/diamonds/shape=Heart",
        },
    ]
  return (
    <div className='k_shop_by_shap_section'>
        <Container className='k_customeHome_container'>
<Row>
    <Col sm={12} md={12} lg={12}>
    <div className='k_layout_heading'> 
<h2>Shop By Shape</h2>
    </div>
    </Col>
    <Col sm={12} md={12} lg={12}>
    <Slider {...settingsShap}>
     {ShapData.map((val, index)=>(
        <div className='k_shop_by_shap_box'>
      <div className='k_shop_by_shap_img_text_box' onClick={()=> history.push(val.url)}>
<Image src={val.img} alt={val.name + "Image"} className="img-fluid"/>
<h6>{val.name}</h6>
      </div>
      </div>
     ))} 
      
    </Slider></Col>
</Row>
        </Container>
    </div>
  )
}

export default ShopByShap