import React, { useEffect } from 'react'
import { Col, Container, Row, Image, Button } from 'react-bootstrap'
import anniversery from '../../../../Assets/images/karson-new-layout-img/anniversery-ring-bands.webp'
import labgrown from '../../../../Assets/images/karson-new-layout-img/Earrings-new.webp'
import RingEnhances from '../../../../Assets/images/karson-new-layout-img/Bracelets-new.webp'
import Slider from 'react-slick'
import { useHistory } from 'react-router-dom'

const AnniversaryAndEternity = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    const settingsAniversery = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay:true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay:true,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay:true,
        }
      }
    ]
      };
      const AnniverseyData = [
        {
            name: "Wedding Bands",
            img: anniversery,
            url: "/jewelry/wedding-rings",
        },
        {
            name: "Earrings",
            img: labgrown,
            url: "/jewelry/earrings",
        },
        {
            name: "Bracelets",
            img: RingEnhances,
            url: "/jewelry/bracelets",
        },
       
    ]
  return (
    <div className='k_aniversary_eternity_section'>
        <Container className='k_customeHome_container'>
            <Row>
            <Col sm={12} md={12} lg={12}>
    <div className='k_layout_heading'> 
<h2>SHOP BY STYLE</h2>
    </div>
    </Col>
    <Col sm={12} md={12} lg={12}>
    <Slider {...settingsAniversery}>
               {AnniverseyData.map((val, index)=>(
                <div className='k_bands_img_text_box_outer'>
 <div className='k_bands_img_text_box'>
                        <Image src={val.img} alt="bands For Him Image" className='img-fluid'/>
                        <div className='k_bands_text_box'>
<h2 className='name'>{val.name}</h2>
<Button className='london_banner_btn_fill' onClick={()=> history.push(val.url)}>Shop Now</Button>
                        </div>
                </div>
                </div>
               
               ))} 
               </Slider>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AnniversaryAndEternity