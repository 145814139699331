import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import bandsHer from '../../../../Assets/images/karson-new-layout-img/wb-for-her.webp'
import bandsHim from '../../../../Assets/images/karson-new-layout-img/wb-for-him.webp'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'


const WeddingBands = () => {
    const history = useHistory()
  return (
    <div className='k_wedding_bands_Section'>
        <Container className='k_customeHome_container'>
            <Row>
                <Col sm={12} md={6} lg={6}>
                <div className='k_bands_img_text_box'>
                        <Image src={bandsHer} alt="bands For Her Image" className='img-fluid'/>
                        <div className='k_bands_text_box'>
<h2>Wedding Bands<br></br>For Her</h2>
<Button className='london_banner_btn_fill' onClick={()=> history.push("/jewelry/wedding-rings")}>Shop Now</Button>
                        </div>
                </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                <div className='k_bands_img_text_box'>
                        <Image src={bandsHim} alt="bands For Him Image" className='img-fluid'/>
                        <div className='k_bands_text_box'>
<h2>Wedding Bands<br></br>For Him</h2>
<Button className='london_banner_btn_fill' onClick={()=> history.push("/jewelry/wedding-rings")}>Shop Now</Button>
                        </div>
                </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default WeddingBands