import React, { useEffect } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRightShort } from 'react-icons/bs';
import Slider from "react-slick";


const LondonBanner = () => {
  const history = useHistory();

  const banner = localStorage.getItem("bw-bannerdata")
    ? JSON.parse(localStorage.getItem("bw-bannerdata"))
    : [];
  const bannerimg = isMobileOnly ? banner[0]?.mobile_image : banner[0]?.image;
  var settings = {
    dots: false,
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider london_banner" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={12} className="p-0">
          <Slider {...settings} className="overflow-hidden">
      <div>
      <div
        className="london_banner"
      >
          <Container  className="k_customeHome_container">
            <Row className="m-auto w-100">
              <Col xl={12} lg={12} md={12} className="ml-auto">
                <div className="london_banner_text">
                  <h1>
                  Fashion Rings
                  </h1>
                  {/* <p> 
                  Discover stunning natural and lab-grown diamonds, each offering exceptional beauty, brilliance, and value.
                  </p> */}

                 <div className="london_banner_btn_main">
                 <Button className="london_banner_btn_fill" onClick={(e) => { history.push('/jewelry/fashion-rings' )}}> Shop Now  </Button>
                 </div>
                </div>
              </Col>
            </Row>
          </Container>
    
      </div>
      </div>
      <div>
      <div
        className="london_banner london_banner1"
      >
          <Container  className="k_customeHome_container">
            <Row className="m-auto w-100">
              <Col xl={12} lg={12} md={12} className="ml-auto">
                <div className="london_banner_text">
                  <h1>
                  Wedding  Rings
                  </h1>

                 <div className="london_banner_btn_main">
                 <Button className="london_banner_btn_fill" onClick={(e) => { history.push('/jewelry/wedding-rings' )}}> Shop Now  </Button>
                 </div>
                </div>
              </Col>
            </Row>
          </Container>
    
      </div>
      </div>
      
    </Slider>
          </Col>
        </Row>

      </Container>
 


    
    </>
  );
};

export default LondonBanner;
