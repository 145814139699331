import React from "react";
import { Link } from "react-router-dom";
import '../assets/css/LondonFooter.css'
import {client_name , Email_details, contact_number, Address_details} from '../../../../Helpers/request'
import { Image } from "react-bootstrap";
import Badge from "../../../../Assets/images/badge.png";
function LondonFooter() {
  return (
    <>
      <div className="footer2">
        <div className="main-footer2">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center mb-4 badge-image-wrap">
                <Link to="/"><Image src={Badge} alt="" className="w-25" /></Link>
              </div>
              <div className="col-sm-12">
                <div className="usefull-links">
                  <ul>
                    <li>
                       <Link to="/diamonds">Diamonds </Link>
                    </li>
                    {/* <li>
                       <Link to="/gemstones">
                        Gemstones
                       </Link>
                    </li> */}
                    <li>
                       <Link to="/jewelry/fashion-rings">
                       Fashion Rings
                       </Link>
                    </li>
                    <li>
                       
                       <Link to="/jewelry/wedding-rings">
                        Wedding Rings
                       </Link>
                    </li>
                    <li>
                       <Link to="/jewelry/fine-jewelry">
                        Fine jewelry
                       </Link>
                    </li>
                    <li>
                       
                       <Link to="/education">
                        Education
                       </Link>
                    </li>
                    <li>
                       <Link to="/our-story">About Us </Link>
                    </li>
                    <li>
                       <Link to="/contact">Contact Us </Link>
                    </li>
                  </ul>
                </div>
                <div className="usefull-links mb_25">
                  <ul>
                    {/* <li>
                       
                       <Link to="#">
                        Cookies Policy
                       </Link>
                    </li> */}
                    <li>
                       
                       <Link to="/terms-and-conditions">
                        Terms Conditions
                       </Link>
                    </li>
                    <li>
                       
                       <Link to="/privacy-policy">
                        Privacy Policy
                       </Link>
                    </li>
                    <li>
                       
                       <Link to="/returns-policy">
                       Return  Policy
                       </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="get-itouch">
                  <ul>
                    <li>
                      {/*<i class="fa fa-volume-control-phone"></i>*/}
                       <a href={contact_number.link}>{contact_number.text} </a>
                    </li>
                    <li>
                      {/*<i class="fa fa-envelope dark"></i>*/}
                       <a href={Email_details.link}>
                     {Email_details.text}
                       </a>
                       {/* <Link to="mailto:info@belgiumwebnet.com">
                        info@belgiumwebnet.com
                       </Link> */}
                    </li>
                    {/* <li>
                       <a href={Address_details.link} target="_blank">
                        <span>{Address_details.textLine1} {Address_details.textLine2}</span>
                       </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-12">
                <div className="block-footer2 text-center">
                  <div className="social-network-footer ">
                    <Link to="#"
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                     </Link> 
                    <Link to="#"
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                     </Link> 
                    <Link to="#"
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                     </Link> 
                    <Link to="#"
                      className="inline-block round"
                      target="_blank"
                    >
                      <i className="fa fa-pinterest" />
                     </Link> 
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-txt">
                  <p>
                    <span>
                     {client_name.c_name} ©  {new Date().getFullYear()} All Rights Reserved. Designed and
                      Developed by: &nbsp; 
                    </span> 
                     <a href="http://belgiumwebnet.com/" target="_blank" rel="noopener noreferrer"> Belgium Webnet </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* desktop view ends*/}
        </div>
      </div>
    </>
  );
}

export default LondonFooter;
