import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function VideoBanner() {
  const history = useHistory();
  return (
    <>
      <div className="Lt_videoBanner_main">
        {/* <ReactPlayer
          url="https://dl2vs6wk2ewna.cloudfront.net/scrap/spruce-video.mp4"
          width="100%"
          height="100%"
          playing
          loop
          muted
          playsinline
        /> */}
<Container>
  <Row>
    <Col sm={12} md={12} lg={12}>
    <div className="Lt_video_banner_content">
          <h2>Necklaces</h2>
          {/* <p>At Karson Diamond Brokers, we are passionate about bringing you exquisite jewelry that captures the essence of elegance and sophistication ...</p> */}
          <Button className="london_banner_btn_outline" variant="outline-dark" onClick={()=> history.push("/jewelry/necklaces")}> Shop Now </Button>
        </div>
    </Col>
  </Row>
</Container>
      
      </div>
    </>
  );
}

export default VideoBanner;
