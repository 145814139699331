import React, { useState } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row, Form, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { base_url, currency, helmet_url, postHeader, contact_number, Email_details } from '../../../../Helpers/request';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function NewsLetter() {
  const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            // currency_code: currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }

  return (
    <>
      <section className="Lt_NewsLetter_section">
        <Container>
          <Row className="m-0 w-100">
            {/* <Col lg={6}>
              <div className="Lt_NewsLetter_content">
                <h2> Join the list </h2>
                <p>
                  Become a preferred subscriber and get a special offer on your
                  purchase.
                </p>

                <div className="rcs_signup_to_input_field">
                  <Form className="gs_news_form w-100" onSubmit={newsletter}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Email Address..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        type="email"
                        value={newsletteremail}
                        required
                        onChange={(e) => setNewsletteremail(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        type="submit"
                        id="button-addon2"
                      >
                        Subscribe
                      </Button>
                    </InputGroup>
                  </Form>
                
                </div>
              </div>
            </Col> */}
            <Col lg={12} className="Lt_NewsLetter_border_left border-left-0">
              <div className="Lt_NewsLetter_content ">
                <h2> Need Help? </h2>
                <p>
                  Call us <a href={contact_number.link}> {contact_number.text} </a><br></br>Monday through
                  Friday, 9 a.m. - 3 p.m. <br></br> Email us  <a href={Email_details.link}>{Email_details.text}</a>
                </p>

                <Button className="london_banner_btn_fill" onClick={() => { history.push('/contact') }}> Contact Us </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default NewsLetter;
