import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import necklace from '../../../../Assets/images/karson-new-layout-img/shop-by-categories/necklace.webp'
import rings from '../../../../Assets/images/karson-new-layout-img/shop-by-categories/rings.webp'
import earrings from '../../../../Assets/images/karson-new-layout-img/shop-by-categories/earrings.webp'
import bracelets from '../../../../Assets/images/karson-new-layout-img/shop-by-categories/bracelets.webp'
import chains from '../../../../Assets/images/karson-new-layout-img/shop-by-categories/chains.webp'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'

const ShopByCategory = () => {
        const history = useHistory();
        const settingsShap = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              autoplay:true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay:true,
              dots: true, 
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay:true,
              dots: true, 
            }
          }
        ]
          };
        const ShopByCategoryData = [
            {
                name: "necklace",
                img: necklace,
                url: "/jewelry/necklaces",
            },
            {
                name: "rings",
                img: rings,
                url: "/jewelry/fashion-rings",
            },
            {
                name: "earrings",
                img: earrings,
                url: "/jewelry/earrings",
            },
            {
                name: "bracelets",
                img: bracelets,
                url: "/jewelry/bracelets",
            },
            {
                name: "pendants",
                img: chains,
                url: "/jewelry/pendants",
            }
        ]
  return (
    <div className='k_ShopByCategory_section'>
        <Container className='k_customeHome_container'>
        <Row>
    <Col sm={12} md={12} lg={12}>
    <div className='k_layout_heading'> 
<h2>SHOP BY CATEGORY</h2>
    </div>
    </Col>
    <Col sm={12} md={12} lg={12}>
    <Slider {...settingsShap}>
     {ShopByCategoryData.map((val, index)=>(
        <div className='k_shop_by_category_box'>
      <div className='k_shop_by_shap_img_text_box' onClick={()=> history.push(val.url)}>
<Image src={val.img} alt={val.name + "Image"} className="img-fluid"/>
<h6>{val.name}</h6>
      </div>
      </div>
     ))} 
      
    </Slider></Col>
    <Col sm={12} md={12} lg={12}>
    <p className='text-center'><i>Karson Diamond Brokers prioritizes website accessibility, following WCAG 2.2 Level AA guidelines, and welcomes feedback for ongoing improvements.</i></p>
    </Col>
</Row>
        </Container>
    </div>
  )
}

export default ShopByCategory